import React, { useState } from 'react';
import { ModulesWidget } from '../../../_metronic/partials/dashboard/ModulesWidget';

// Definir o tipo dos itens de transparência
interface TransparencyItem {
    icon: string;
    name: string;
}

// Função para agrupar itens em chunks de tamanho específico
const chunkArray = (array: TransparencyItem[], size: number) =>
    array.reduce((result: TransparencyItem[][], item: TransparencyItem, index: number) => {
        const chunkIndex = Math.floor(index / size);
        if (!result[chunkIndex]) {
            result[chunkIndex] = []; // Criar um novo chunk
        }
        result[chunkIndex].push(item);
        return result;
    }, []);

// Definindo o tipo da propriedade transparencyItems
interface ModulesComponentProps {
    transparencyItems: TransparencyItem[];
}

const ModulesComponent: React.FC<ModulesComponentProps> = ({ transparencyItems }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    // Função para navegar para o slide anterior
    const handlePrev = () => {
        const newIndex = (activeIndex + chunkedItems.length - 1) % chunkedItems.length;
        setActiveIndex(newIndex);
    };

    // Função para navegar para o próximo slide
    const handleNext = () => {
        setActiveIndex((activeIndex + 1) % chunkedItems.length);
    };

    // Agrupar os itens em chunks
    const chunkedItems = chunkArray(transparencyItems, 12);

    return (
        <>
            <div id="carouselExampleIndicators" className="carousel card slide">
                <div className="carousel-inner">
                    {chunkedItems.map((chunk, index) => (
                        <div
                            className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
                            key={index}
                        >
                            <div className="row g-5 g-xl-2 mb-5">
                                {chunk.map((item, itemIndex) => (
                                    <div className="col-sm-6 col-md-2 text-center mb-9" key={itemIndex}>
                                        <ModulesWidget
                                            bootstrapIcon={`${item.icon} fs-3x text-white`}
                                            title={item.name}
                                            description={`Descrição ${itemIndex}`}
                                            className=""
                                            titleClass="text-gray-900 fw-bold text-hover-primary fs-5"
                                            descriptionClass="text-muted fs-6 fw-semibold"
                                            iconClass="rounded-circle border-primary bg-opacity-20 bg-primary bg-hover-primary"
                                            // iconClass="octagon"
                                            imageBg="/media/misc/gradient.png"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev"
                    onClick={handlePrev}
                    style={{ width: "4%" }}
                >
                    <i className="bi bi-caret-left fs-4x text-gray-400" aria-hidden="false"></i>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next"
                    onClick={handleNext}
                    style={{ width: "4%" }}
                >
                    <i className="bi bi-caret-right fs-4x text-gray-400" aria-hidden="false"></i>
                    <span className="visually-hidden">Next</span>
                </button>
            </div >
        </>
    );
};

export default ModulesComponent;
