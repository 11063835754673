/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { toAbsoluteUrl } from '../../../helpers';

type Props = {
  className: string;
  title: string;
  description: string;
  avatar: string;
  buttonName: string;
};

const StatisticsWidget2: React.FC<Props> = ({ className, title, description, avatar, buttonName }) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column flex-md-row align-items-center pt-2 pb-0'>
        {/* begin::Content */}
        <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-md-2 text-center text-md-start'>
          <a href='#' className='fw-bold text-dark fs-4 mb-2 text-hover-primary'>
            <h2>{title}</h2>
          </a>

          <span
            className='fw-semibold text-muted fs-5 mb-3'
            dangerouslySetInnerHTML={{ __html: description }}
          ></span>

          <div className='d-flex justify-content-center justify-content-md-start h-75'>
            <a
              href='#'
              className='btn btn-sm btn-primary fw-semibold'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_campaign'
            >
              {buttonName}
            </a>
          </div>
        </div>
        {/* end::Content */}

        {/* begin::Image */}
        <div className='d-flex justify-content-center align-items-center'>
          <img src={toAbsoluteUrl(avatar)} alt='' className='img-fluid mx-auto h-100px h-lg-150px' />
        </div>
        {/* end::Image */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { StatisticsWidget2 };
