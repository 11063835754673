/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='color-swatch'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem to='/dashboard' icon='switch' title='links útil aqui' fontIcon='bi-layers' />
      <AsideMenuItem to='/dashboard' icon='switch' title='links útil aqui' fontIcon='bi-layers' />
      <AsideMenuItem to='/dashboard' icon='switch' title='links útil aqui' fontIcon='bi-layers' />
      <AsideMenuItem to='/dashboard' icon='switch' title='links útil aqui' fontIcon='bi-layers' />
      <AsideMenuItem to='/dashboard' icon='switch' title='links útil aqui' fontIcon='bi-layers' />
      <AsideMenuItem to='/dashboard' icon='switch' title='links útil aqui' fontIcon='bi-layers' />
      <AsideMenuItem to='/dashboard' icon='switch' title='links útil aqui' fontIcon='bi-layers' />
      <AsideMenuItem to='/dashboard' icon='switch' title='links útil aqui' fontIcon='bi-layers' />
      <AsideMenuItem to='/dashboard' icon='switch' title='links útil aqui' fontIcon='bi-layers' />
      <AsideMenuItem to='/dashboard' icon='switch' title='links útil aqui' fontIcon='bi-layers' />
      <AsideMenuItem to='/dashboard' icon='switch' title='links útil aqui' fontIcon='bi-layers' />
      <AsideMenuItem to='/dashboard' icon='switch' title='links útil aqui' fontIcon='bi-layers' />



      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>


      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href="google.com"
        >
          <span className='menu-icon'>
            <KTIcon iconName='document' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>
    </>
  )
}
