import { useIntl } from 'react-intl'
import { Image } from 'react-bootstrap';
import { PageTitle } from '../../../_metronic/layout/core'
import { KTIcon } from '../../../_metronic/helpers'
import {
  NewsWidget1,
  MixedWidget11,
  MixedWidget1,
  StatisticsWidget2,
  TilesWidget5,
} from '../../../_metronic/partials/widgets'
import { useState } from 'react';
import { ModulesWidget } from '../../../_metronic/partials/dashboard/ModulesWidget';
import ModulesComponent from './ModulesComponent';

const DashboardPage: React.FC = () => {
  // Criação do array para repetição
  const transparencyItems = [
    { name: 'Assistência', icon: 'bi bi-person-heart' },
    { name: 'Ato Administrativo', icon: 'bi bi-file-earmark-text' },
    { name: 'Audiências Públicas', icon: 'bi bi-people' },
    { name: 'CACS FUNDEB', icon: 'bi bi-bookmark-star' },
    { name: 'Carta de Serviços aos Usuários', icon: 'bi bi-card-list' },
    { name: 'Combate ao COVID-19', icon: 'bi bi-shield-virus' },
    { name: 'Concursos e Processos Seletivos', icon: 'bi bi-file-medical' },
    { name: 'Conselhos Municipais', icon: 'bi bi-person-check' },
    { name: 'Contracheque Online', icon: 'bi bi-file-spreadsheet' },
    { name: 'Contratos', icon: 'bi bi-file-earmark-check' },
    { name: 'Convênios', icon: 'bi bi-people-arrows' },
    { name: 'Cronologia de Pagamento', icon: 'bi bi-calendar-check' },
    { name: 'Decretos', icon: 'bi bi-journal-bookmark' },
    { name: 'Despesa', icon: 'bi bi-cash-stack' },
    { name: 'Dívida Ativa', icon: 'bi bi-graph-down-arrow' },
    { name: 'Diárias', icon: 'bi bi-calendar-range' },
    { name: 'Diário Oficial', icon: 'bi bi-journal-text' },
    { name: 'Dispensas e Inexigibilidade', icon: 'bi bi-x-circle' },
    { name: 'Educação', icon: 'bi bi-mortarboard' },
    { name: 'Emendas Parlamentares', icon: 'bi bi-pencil-square' },
    { name: 'e-OUV', icon: 'bi bi-mic' },
    { name: 'e-SIC', icon: 'bi bi-inbox' },
    { name: 'Estrutura Organizacional', icon: 'bi bi-diagram-3' },
    { name: 'Folha de Pagamento', icon: 'bi bi-receipt' },
    { name: 'FUNDEB', icon: 'bi bi-bar-chart' },
    { name: 'Julgamento das Contas', icon: 'bi bi-gavel' },
    { name: 'LAI', icon: 'bi bi-info-circle' },
    { name: 'LC-173', icon: 'bi bi-book-half' },
    { name: 'LDO / LOA / PPA', icon: 'bi bi-file-earmark-bar-graph' },
    { name: 'Legislação', icon: 'bi bi-file-text' },
    { name: 'LGPD', icon: 'bi bi-lock' },
    { name: 'Licitações', icon: 'bi bi-clipboard-data' },
    { name: 'MDE', icon: 'bi bi-globe' },
    { name: 'Obras', icon: 'bi bi-hammer' },
    { name: 'Passagens', icon: 'bi bi-ticket-perforated' },
    { name: 'Patrimônio Público', icon: 'bi bi-building' },
    { name: 'PCA', icon: 'bi bi-bar-chart-line' },
    { name: 'Pesquisa de Satisfação', icon: 'bi bi-emoji-smile' },
    { name: 'Planejamento', icon: 'bi bi-compass' },
    { name: 'Portal do Contribuinte', icon: 'bi bi-person-badge' },
    { name: 'Portarias', icon: 'bi bi-file-earmark-break' },
    { name: 'Radar de Transparência Pública', icon: 'bi bi-eye' },
    { name: 'Receitas', icon: 'bi bi-graph-up' },
    { name: 'Regimento Interno', icon: 'bi bi-book' },
    { name: 'Relatórios de Credores', icon: 'bi bi-person-lines-fill' },
    { name: 'Relatórios LRF', icon: 'bi bi-file-bar-graph' },
    { name: 'Renúncia de Receita', icon: 'bi bi-percent' },
    { name: 'Requerimentos', icon: 'bi bi-ui-checks' },
    { name: 'Restos a Pagar', icon: 'bi bi-cash' },
    { name: 'Sala do Empreendedor', icon: 'bi bi-house-door' },
    { name: 'Saúde', icon: 'bi bi-heart-pulse' },
    { name: 'Terceirizados', icon: 'bi bi-people-fill' },
    { name: 'Transferências', icon: 'bi bi-arrow-left-right' }
  ];


  const [visibleDiv, setVisibleDiv] = useState<null | number>(1);
  const [visibleBody, setVisibleBody] = useState<null | number>(1);

  const toggleVisibility = () => {
    setVisibleDiv(visibleDiv === 1 ? 2 : 1);
  };

  const toggleVisibilityBody = () => {
    setVisibleBody(visibleBody === 1 ? 2 : 1);
  };

  return (
    <>

      {/* Botão para alternar a visibilidade */}
      <button onClick={toggleVisibility} className="btn btn-sm btn-primary mb-5 me-5">
        {visibleDiv ? 'Alternar topo' : 'Alternar topo'}
      </button>

      <button onClick={toggleVisibilityBody} className="btn btn-sm btn-primary mb-5">
        {visibleDiv ? 'Alternar icones' : 'Alternar icones'}
      </button>



      <div className="d-md-flex flex-md-wrap">
        <div className='row g-5 g-xl-3 mb-5'>

          <div className='col-xl-7'>
            <NewsWidget1 className='card-xxl-stretch mb-xl-3 align-items-center shadow-lg' />
          </div>


          {visibleDiv === 2 && (
            <div className='col-xl-5 d-md-inline-flex flex-md-wrap'>

              <div className='col-md-12 pe-md-2 mb-3 mb-md-0'>
                <StatisticsWidget2
                  className='card-xl-stretch shadow'
                  avatar='/media/svg/illustrations/sigma/volume-1.svg'
                  title='Pesquisa de satisfação'
                  description='Sua Opinião é muito importante para nosso aperfeiçoamento!'
                  buttonName='Participe'
                />
              </div>

              <div className='col-md-12 pe-md-2 mb-3 mb-md-0'>
                <StatisticsWidget2
                  className='card-xl-stretch shadow'
                  avatar='/media/illustrations/sigma-1/16.png'
                  title='Links úteis'
                  description='Acesso fácil, informações claras.'
                  buttonName='Participe'
                />
              </div>

              <div className='col-md-12 pe-md-2 mb-3 mb-md-0'>
                <StatisticsWidget2
                  className='card-xl-stretch shadow'
                  avatar='/media/svg/illustrations/sigma/question.svg'
                  title='Dúvidas frequentes'
                  description='Encontre Respostas para Suas Dúvidas Aqui!'
                  buttonName='Detalhes'
                />
              </div>

            </div>
          )}


          {visibleDiv === 1 && (
            <div className='col-xl-5 d-md-inline-flex flex-md-wrap' >
              {/* <SummaryWidget /> */}
              {/* <MixedWidget11
                className='card-xxl-stretch-50 mb-5 mb-xl-8'
                chartColor='primary'
                chartHeight='175px'
              /> */}

              <MixedWidget1 className='card-xl-stretch mb-xl-8 col-12' color='primary' />
            </div>


            // <div className='col-xl-5 d-md-inline-flex flex-md-wrap'>

            //   <div className='col-md-6 pe-md-2 mb-3 mb-md-0'>
            //     <StatisticsWidget5
            //       className='card-xl-stretch mb-xl-4 shadow'
            //       svgIcon='chart-simple'
            //       color='white'
            //       iconColor='primary'
            //       title='R$ 50.000.000,00'
            //       description='Educação'
            //     />
            //   </div>

            //   <div className='col-md-6 pe-md-2 mb-3 mb-md-0'>
            //     <StatisticsWidget5
            //       className='card-xl-stretch mb-xl-4 shadow'
            //       svgIcon='cheque'
            //       color='danger'
            //       iconColor='white'
            //       title='R$ 30.000,00'
            //       titleColor='white'
            //       description='Cultura'
            //       descriptionColor='white'
            //     />
            //   </div>

            //   <div className='col-md-6 pe-md-2 mb-3 mb-md-0'>
            //     <StatisticsWidget5
            //       className='card-xl-stretch mb-xl-4 shadow'
            //       svgIcon='briefcase'
            //       color='warning'
            //       iconColor='white'
            //       title='R$ 50.000,00'
            //       titleColor='white'
            //       description='Saúde'
            //       descriptionColor='white'
            //     />
            //   </div>

            //   <div className='col-md-6 pe-md-2 mb-3 mb-md-0'>
            //     <StatisticsWidget5
            //       className='card-xl-stretch mb-5 mb-xl-4 shadow'
            //       svgIcon='chart-pie-simple'
            //       color='info'
            //       iconColor='white'
            //       title='R$ 50.000,00'
            //       titleColor='white'
            //       description='Urbanismo'
            //       descriptionColor='white'
            //     />
            //   </div>

            //   <div className='col-md-6 pe-md-2 mb-3 mb-md-0'>
            //     <StatisticsWidget5
            //       className='card-xl-stretch mb-xl-2 mb-5 shadow'
            //       svgIcon='briefcase'
            //       color='primary'
            //       iconColor='white'
            //       title='R$ 50.000,00'
            //       titleColor='white'
            //       description='Assistência Social'
            //       descriptionColor='white'
            //     />
            //   </div>

            //   <div className='col-md-6 pe-md-2 mb-3 mb-md-0'>
            //     <StatisticsWidget5
            //       className='card-xl-stretch mb-xl-2 mb-5 shadow'
            //       svgIcon='chart-pie-simple'
            //       color='success'
            //       iconColor='white'
            //       title='R$ 50.000,00'
            //       titleColor='white'
            //       description='Infraestrutura'
            //       descriptionColor='white'
            //     />
            //   </div>

            // </div>
          )}



          {/* <div className='col-xl-2'>
            <div className='col-xl-12'>
              <TilesWidget3 className='card-xl-stretch mb-5 mb-xl-8' />
            </div>
          </div> */}

        </div>




        {/* begin::Row */}
        <div className='row g-5 g-xl-8 col-12'>
          {/* begin::Col */}
          <div className='col-xl-4'>
            <MixedWidget11
              className='card-xl-stretch mb-xl-8'
              chartColor='info'
              chartHeight='200px'
              chartTitle='Covide-19'
              chartSubTitle='Resumo'
            />
          </div>
          {/* end::Col */}

          {/* begin::Col */}
          <div className='col-xl-4'>
            <MixedWidget11
              className='card-xl-stretch mb-xl-8'
              chartColor='warning'
              chartHeight='200px'
              chartTitle='Folha de pagamento'
              chartSubTitle='Resumo'
            />
          </div>
          {/* end::Col */}

          {/* begin::Col */}
          <div className='col-xl-4'>
            <MixedWidget11
              className='card-xl-stretch mb-xl-8'
              chartColor='primary'
              chartHeight='200px'
              chartTitle='Licitações'
              chartSubTitle='Resumo'
            />
          </div>
          {/* end::Col */}
        </div>
        {/* end::Row */}





        <div className='row g-5 g-xl-8 col-12'>
          <div className='d-flex flex-wrap flex-stack mb-6'>
            <h3 className='fw-bolder my-2'>
              Localizar
              <span className='fs-6 text-gray-400 fw-bold ms-1'>Encontre tudo em um só lugar</span>
            </h3>

            <div className='d-flex my-2'>
              <div className='d-flex align-items-center position-relative me-4'>
                <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                <input
                  type='text'
                  id='kt_filter_search'
                  className='form-control form-control-white form-control-sm min-w-250px max-w-300px ps-9'
                  placeholder='Localizar'
                />
              </div>

              <button className='btn btn-primary btn-sm'>
                Localizar
              </button>
            </div>
          </div>
        </div>




        <div className='row col-12 g-5 g-xl-3 mb-5'>

          {visibleBody === 2 && (
            <>
              {transparencyItems.map((item, index) => (
                <div className='col-md-2'>
                  <TilesWidget5
                    className='card-md-stretch bg-body shadow card-xl-stretch '
                    svgIcon={item.icon}
                    titleClass='text-dark'
                    descriptionClass='text-muted'
                    iconClass='text-primary'
                    title={item.name}
                    description='Descrição'
                  />
                </div>
              ))}
            </>
          )}

          {visibleBody === 1 && (
            // <div className="card">
            //   <div className='row g-5 g-xl-3 mb-5'>
            //     {transparencyItems.map((item, index) => (
            //       <div className="col-sm-6 col-md-2 text-center mb-9" key={index}>
            //         <ModulesWidget
            //           bootstrapIcon={`${item.icon} fs-3x text-black`}
            //           // imgIcon="/media/misc/images.jpeg"
            //           title={item.name}
            //           description={`Descrição ${index}`}
            //           className=""
            //           titleClass="text-gray-900 fw-bold text-hover-primary fs-5"
            //           descriptionClass="text-muted fs-6 fw-semibold "
            //           iconClass="rounded-circle border-primary bg-opacity-20 bg-primary bg-hover-primary"
            //           imageBg=""
            //         />
            //       </div>
            //     ))}
            //   </div>

            // </div>



            <>
              {/* Renderizando o componente ModulesComponent */}
              <ModulesComponent transparencyItems={transparencyItems} />
            </>
          )}











          {/* <div className='row g-5 g-xl-3 mb-5'>
            {transparencyItems.map((item, index) => (
              <div className="col-sm-6 col-md-3 col-lg-1 text-center mb-9" key={index}>
                <ModulesWidget
                  bootstrapIcon={`${item.icon} fs-3x text-warning`}
                  imageBg="/media/misc/bg-gradient.png"
                  title={item.name}
                  description={`Descrição ${index}`}
                  className=""
                  titleClass="text-gray-900 fw-bold text-hover-primary fs-3"
                  descriptionClass="text-muted fs-6 fw-semibold "
                  iconClass="octagon"
                />
              </div>
            ))}
          </div> */}






          {/* {transparencyItems.map((item, index) => (
            <div className="col text-center mb-9">
              <div
                className="octagon mx-auto mb-2 d-flex w-150px h-150px bgi-no-repeat bgi-size-contain bgi-position-center"
                style={{ backgroundImage: "url('/media/logos/agape.png')" }}
              >
              </div>
              <div className="mb-0">
                <a href="/teste.html" className="text-gray-900 fw-bold text-hover-primary fs-3">
                  {item.name}
                </a>
                <div className="text-muted fs-6 fw-semibold">Descrição 3.1.8</div>
              </div>
            </div>
          ))} */}


          {/* {transparencyItems.map((item, index) => (
            <div key={index} className='col-xl-2 d-flex'>
              <div className='col-12 col-md-12 col-xl flex-grow-*'>
                <Card4 icon={item.icon}
                  title={item.name}
                  description='Descrição' />
              </div>
            </div>
          ))} */}

        </div>

      </div >

      {visibleDiv === 1 && (
        <div>
          <div className='row'>
            {/* <TilesWidget2 title2="Sua Opinião é muito importante para nosso aperfeiçoamento!" title="Pesquisa de satisfação" className='card-xxl-stretch mb-xl-3' /> */}

            <div className='col-md-4 pe-md-2 mb-3 mb-md-0'>
              <StatisticsWidget2
                className='card-xl-stretch shadow h-100'
                avatar='/media/svg/illustrations/sigma/volume-1.svg'
                title='Pesquisa de satisfação'
                description='Sua Opinião é muito importante para nosso aperfeiçoamento!'
                buttonName='Participe'
              />
            </div>

            <div className='col-md-4 pe-md-2 mb-3 mb-md-0'>
              <StatisticsWidget2
                className='card-xl-stretch shadow h-100'
                avatar='/media/illustrations/sigma-1/16.png'
                title='Links úteis'
                description='Acesso fácil, informações claras.'
                buttonName='Participe'
              />
            </div>

            <div className='col-md-4 pe-md-2 mb-3 mb-md-0'>
              <StatisticsWidget2
                className='card-xl-stretch shadow h-100'
                avatar='/media/svg/illustrations/sigma/question.svg'
                title='Dúvidas frequentes'
                description='Encontre Respostas para Suas Dúvidas Aqui!'
                buttonName='Detalhes'
              />
            </div>

          </div>
        </div>
      )}



      <div className="row g-5 g-xl-4 m-15">
        <div className="d-flex justify-content-center">
          <Image
            src={`${process.env.PUBLIC_URL}/media/logos/tce.png`}
            fluid
            style={{ width: '200px' }}
          />
        </div>
      </div>


    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
