/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../helpers';
import { Carousel, Image } from 'react-bootstrap';

type Props = {
    className: string;
};

const NewsWidget1: React.FC<Props> = ({ className }) => {
    const [isPlaying, setIsPlaying] = useState(true);

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    return (
        <div className={`card ${className}`}>
            {/* begin::Body */}
            <div className="card-body p-1 p-md-2">
                {/* begin::Post */}
                <div className="mb-1">
                    {/* begin::Carousel */}
                    <Carousel
                        controls={true}
                        indicators={false}
                        interval={isPlaying ? 3000 : null}
                        nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />}
                        prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />}
                    >
                        <Carousel.Item>
                            <Image src="/media/news/image.jpg" fluid className="rounded" />
                            <Carousel.Caption className="text-start mb-10">
                                <h1 className="fw-bold text-white fs-1 mb-2 text-hover-primary">
                                    CONHEÇA NOSSAS SOLUÇÕES
                                </h1>
                                <span>Para governaça pública</span>
                            </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                            <Image src="/media/news/image.jpg" fluid className="rounded" />
                            <Carousel.Caption className="text-start mb-10">
                                <h1 className="fw-bold text-white fs-1 mb-2 text-hover-primary">
                                    Ágape investe em treinamento
                                </h1>
                                <span>Para seus colaboradores</span>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                    {/* end::Carousel */}

                    {/* begin::Toolbar */}
                    <div className="d-flex align-items-center mb-1 mt-5">
                        <button
                            className="btn btn-sm btn-light-info btn-active-light-info active"
                            onClick={handlePlayPause}
                        >
                            {isPlaying ? 'Pause' : 'Play'}
                        </button>
                    </div>
                    {/* end::Toolbar */}
                </div>
                {/* end::Post */}
            </div>
            {/* end::Body */}
        </div>
    );
};

export { NewsWidget1 };
