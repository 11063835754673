import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/' />
      <MenuItem title={intl.formatMessage({id: 'MENU.O_MUNICIPIO'})} to='/a' />
      <MenuItem title={intl.formatMessage({id: 'MENU.LICITACOES'})} to='/b' />
      <MenuItem title={intl.formatMessage({id: 'MENU.LEGISLACAO'})} to='/c' />
      <MenuItem title={intl.formatMessage({id: 'MENU.ESIC'})} to='/s' />
      <MenuItem title={intl.formatMessage({id: 'MENU.LGPD'})} to='/e' />
      <MenuItem title={intl.formatMessage({id: 'MENU.ESTRUTURA_ORGANIZACIONAL'})} to='/x' />
    </>
  )
}
